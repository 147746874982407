import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { retrieve } from './Encryption';
import './EditSummativeAssessments.css';
import { FaEdit, FaDownload, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import 'jspdf-autotable';
import jsPDF from 'jspdf'; // Import jsPDF
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
import { Box, CssBaseline,Typography } from '@mui/material';

const EditSummativeAssessments = () => {
    const { studentId } = useParams();
    const location = useLocation();
    const { student, gradeId, streamId, streamName,grade } = location.state;
    const [subjects, setSubjects] = useState([]);
    const [terms, setTerms] = useState([]);
    const [selectedTerm, setSelectedTerm] = useState('');
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [reportData, setReportData] = useState([]);
 
    const [currentTab, setCurrentTab] = useState('term');
     const [headTeacherSignature, setHeadTeacherSignature]=useState("")

    const navigate = useNavigate();
    const [loadingSubjects, setLoadingSubjects] = useState(true);
    const [editableSubjects, setEditableSubjects] = useState(new Set());
    const [selectedTermName, setSelectedTermName] = useState(''); // New state for term name
    const [termComments, setTermComments]=useState([])
    const [yearComments, setYearComments]=useState([])
    const [canEdit, setCanEdit] = useState(false);
    const [assignments, setAssignments] = useState(new Set());
    const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();

  

  

   
    useEffect(() => {
        setReportData([]);
        setSelectedTerm('');
        setSelectedYear('');
        setEditableSubjects(new Set());
    }, [currentTab]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/subjects/grade/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });
                const data = await response.json();
                setSubjects(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching subjects:', error);
            } finally {
                setLoadingSubjects(false);
            }
        };

        const fetchTerms = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/terms`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });
                const data = await response.json();
                setTerms(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        const fetchYears = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/years`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });
                const data = await response.json();
                setYears(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching years:', error);
            }
        };

   

        fetchSubjects();
        fetchTerms();
        fetchYears();
        // fetchGradeName();
        // fetchStreamName();
    }, [gradeId, streamId]);

    // Fetch summative report for the selected term
    const fetchSummativeReport = async (termId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/summative_reports_per_term/${gradeId}/${studentId}/${termId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            const data = await response.json();
            setReportData(data);
        } catch (error) {
            console.error('Error fetching summative report:', error);
        }
    };

    // Fetch summative report for the selected year
    const fetchYearlySummativeReport = async (yearId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/summative_reports_per_year/${gradeId}/${studentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            const data = await response.json();
            setReportData(data);
        } catch (error) {
            console.error('Error fetching yearly summative report:', error);
        }
    };
    const fetchSummativeTermComments = async (termId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/term_comments_remarks/${gradeId}/${studentId}/${termId}`, {
                method: 'GET',        
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            const data = await response.json();
            setTermComments(Array.isArray(data) ? data : [data]);
        } catch (error) {
            console.error('Error fetching termly comments:', error);
        }
    };
    const fetchSummativeYearComments = async (termId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/year_comments_remarks/${gradeId}/${studentId}`, {
                method: 'GET',        
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            const data = await response.json();
            setYearComments(Array.isArray(data) ? data : [data]);
        } catch (error) {
            console.error('Error fetching termly comments:', error);
        }
    };
     const fetchHeadTeacherSignature = async () => {
            try {
              const response = await fetch("https://cbc-orfl.onrender.com/get_signature", {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${retrieve().access_token}`,
                },
              });
          
              if (response.ok) {
                const data = await response.json();
                setHeadTeacherSignature(data); 
              } else {
                console.error("Failed to fetch headteacher's signature");
              }
            } catch (error) {
              console.error("Error:", error);
            }
          };
          
    


    const handleTermChange = (event) => {
        const selectedTermId = event.target.value;
        const selectedTermObj = terms.find(term => term.id === selectedTermId);
        setSelectedTerm(selectedTermId);
        setSelectedTermName(selectedTermObj ? selectedTermObj.term_name : ''); // Set term name
        fetchSummativeReport(selectedTermId);
        fetchSummativeTermComments(selectedTermId)
        fetchHeadTeacherSignature()
    };

    const handleYearChange = (event) => {
        const selectedYearId = event.target.value;
        setSelectedYear(selectedYearId);
        fetchYearlySummativeReport(selectedYearId);
        fetchSummativeYearComments();
        fetchHeadTeacherSignature()
    };

    const isTeacherAssignedToSubject = async (subjectId) => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/subject/${subjectId}/${gradeId}/${streamId}/teachers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });
            const data = await response.json();
            return data.teachers.some(teacher => teacher.teacher_id === userId);
        } catch (error) {
            console.error('Error checking teacher assignment:', error);
            return false;
        }
    };

    useEffect(() => {
        const fetchEditableSubjects = async () => {
            if (!loadingSubjects && subjects.length > 0 && userId && (selectedTerm || selectedYear)) {
                const editableSubjectsSet = new Set();
                for (const subject of subjects) {
                    const isAssigned = await isTeacherAssignedToSubject(subject.id);
                    if (isAssigned) {
                        editableSubjectsSet.add(subject.id);
                    }
                }
                setEditableSubjects(editableSubjectsSet);
            }
        };
        fetchEditableSubjects();
        fetchClassTeacherAssignments();
    }, [loadingSubjects, subjects, userId, selectedTerm, selectedYear]);
    
    const handleEdit = (subjectId, report) => {
        navigate(`/view-teacher-dashboard/edit-termly-marks/${studentId}/${selectedTerm}/${subjectId}`, {
            state: { 
                report,
                selectedTermId: selectedTerm,
                gradeId: gradeId,
                grade,
                streamName
            },
        });
    };

     const handleDownloadReport = async () => {
           const doc = new jsPDF();
           const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
           const pageHeight = doc.internal.pageSize.getHeight(); // Get the document height
           const fontSizeHeader = 10; // Font size for headers
           const fontSizeBody = 8; // Font size for body text
           const fontSizeSmall = 6; // Font size for smaller text like address and contact
         
           const fetchLogo = async () => {
               try {
                   let logoUrl = schoolLogo;
         
                   // Replace 'http' with 'https' if necessary
                   if (logoUrl.startsWith('http://')) {
                       logoUrl = logoUrl.replace('http://', 'https://');
                   }
         
                   const response = await fetch(logoUrl);
                   if (!response.ok) throw new Error("Failed to fetch logo");
         
                   const blob = await response.blob();
                   const reader = new FileReader();
         
                   return new Promise((resolve, reject) => {
                       reader.onloadend = () => resolve(reader.result);
                       reader.onerror = reject;
                       reader.readAsDataURL(blob);
                   });
               } catch (error) {
                   console.error("Failed to load logo:", error);
                   return null; // Provide a fallback or skip adding the logo
               }
           };
         
           const logoBase64 = await fetchLogo();
         
           // Add the logo if successfully fetched
           if (logoBase64) {
               const imageWidth = 10; // Set the small width for the logo
               const imageHeight = 10; // Set the small height for the logo
               const logoXPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
               const yPositionLogo = 10; // Top margin for the logo
         
               // Draw circular border around the logo
               const centerX = logoXPosition + imageWidth / 2;
               const centerY = yPositionLogo + imageHeight / 2;
               const radius = imageWidth / 2;
         
               doc.setLineWidth(0.5);
               doc.addImage(logoBase64, 'JPEG', logoXPosition, yPositionLogo, imageWidth, imageHeight);
           }
         
           const fetchSignature = async () => {
               try {
                   let signatureUrl = headTeacherSignature.signature_url;
         
                   // Replace 'http' with 'https' if necessary
                   if (signatureUrl.startsWith('http://')) {
                       signatureUrl = signatureUrl.replace('http://', 'https://');
                   }
         
                   const response = await fetch(signatureUrl);
                   if (!response.ok) throw new Error("Failed to fetch signature");
         
                   const blob = await response.blob();
                   const reader = new FileReader();
         
                   return new Promise((resolve, reject) => {
                       reader.onloadend = () => resolve(reader.result);
                       reader.onerror = reject;
                       reader.readAsDataURL(blob);
                   });
               } catch (error) {
                   console.error("Failed to load signature:", error);
                   return null; // Provide a fallback or skip adding the signature
               }
           };
         
           const signatureBase64 = await fetchSignature();
         
           // Add report type
           const reportType = currentTab === 'term' ? 'Termly' : 'Yearly';
           // Add report type below the school details
           doc.setFontSize(fontSizeHeader);
           const title = `${reportType} Summative Assessment Report`;
           const titleWidth = doc.getTextWidth(title); // Get the width of the text
           // For the title
           const titleXPosition = (pageWidth - titleWidth) / 2; // Center the title text
           doc.text(title, titleXPosition, 40); // Add the text at the calculated position
         
           // Add title and school details
           doc.setFontSize(fontSizeHeader);   
           doc.text(schoolName, pageWidth / 2, 25, { align: 'center' });
           doc.setFontSize(fontSizeSmall);
           doc.text(`Address: ${schoolAddress}`, pageWidth / 2, 28, { align: 'center' });
           doc.text(`Contact: ${schoolContact}`, pageWidth / 2, 31, { align: 'center' });
           doc.text(`Email: ${schoolEmail}`, pageWidth / 2, 34, { align: 'center' });
         
         
         
                 
             // Draw a bordered and shaded box for student information
             const studentInfoX = 34; // X position for the box
             const studentInfoY = 48; // Y position for the box
             const studentInfoWidth = pageWidth - 65; // Box width (centered with 20px padding on each side)
             const studentInfoHeight = 25; // Increased box height to accommodate term name
         
             // Set border and fill colors
             doc.setLineWidth(0.1); // Reduced border weight for a thinner line      
             doc.setFillColor(243, 243, 244); // Light gray fill (RGB)
         
             // Draw the rectangle
             doc.rect(studentInfoX, studentInfoY, studentInfoWidth, studentInfoHeight, 'FD'); // 'FD' = Fill and Draw
         
             doc.setFontSize(fontSizeBody);
             doc.text(`Name: ${student.first_name} ${student.last_name}`, pageWidth / 2, studentInfoY + 5, { align: 'center' });
             doc.text(`Admission Number: ${student.admission_number}`, pageWidth / 2, studentInfoY + 10, { align: 'center' });
             doc.text(`Grade: ${grade} | Stream: ${streamName}`, pageWidth / 2, studentInfoY + 15, { align: 'center' });
         
             // Add the term name if in term view
             if (currentTab === 'term') {
                 doc.text(`Term: ${selectedTermName}`, pageWidth / 2, studentInfoY + 20, { align: 'center' });
             } else if (currentTab === 'yearly') {
                 doc.text(`Year: ${selectedYear}`, pageWidth / 2, studentInfoY + 20, { align: 'center' });
             }
         
             // Define columns based on the report type
             const columns = currentTab === 'term'
                 ? ["Subject", "Exam 1 Marks", "Exam 2 Marks", "Exam 3 Marks", "Term Average Grade"]
                 : ["Subject", "Term 1 Average", "Term 2 Average", "Term 3 Average", "Yearly Average Grade"];
         
             // Map report data to rows
             const rows = reportData.map(report => {
                 return currentTab === 'term'
                     ? [report.subject_name, report.term_exam1_marks, report.term_exam2_marks, report.term_exam3_marks, report.termly_average_grade]
                     : [report.subject_name, report.annual_exam1_marks, report.annual_exam2_marks, report.annual_exam3_marks, report.annual_average_grade];
             });
         
             // Create the table in the PDF
             doc.autoTable({
                 head: [columns],
                 body: rows,
                 startY: 90, // Start below the student details
                 styles: {
                     halign: 'center', // Center-align text in all cells
                 },
                 headStyles: {
                     halign: 'center', // Center-align header text
                 },
             });
         
         
         
         
         
         
         
           // Define the y-position for the signature section
           const yPositionSignature = pageHeight - 40; // Place signature near the bottom of the page
         
           // Add comments section
           const commentsStartY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 20 : 100;
           doc.setFontSize(12);
           doc.text('Attendance Days:', 14, commentsStartY);
           doc.setFontSize(8);
         
           const attendanceDays = currentTab === 'term' && termComments?.[0]?.attendance_days 
               ? termComments[0].attendance_days.toString()
               : "No attendance available";
         
           doc.text(attendanceDays, 14, commentsStartY + 10);
         
           doc.setFontSize(12);
           doc.text('Teacher\'s Comments:', 14, commentsStartY + 30);
           doc.setFontSize(8);
           const teacherComments = currentTab === 'term' ? termComments[0] : yearComments[0];
           const comments = teacherComments ? teacherComments.class_teachers_comments : "No comments available";
           doc.text(comments || "No comments available", 14, commentsStartY + 40);
         
           doc.setFontSize(12);
           doc.text('General Remarks:', 14, commentsStartY + 60);
           doc.setFontSize(8);
           const generalRemarks = teacherComments ? teacherComments.general_remarks : "No remarks available";
           doc.text(generalRemarks || "No remarks available", 14, commentsStartY + 70);
         
          // Add head teacher's name
           doc.setFontSize(12);
           doc.text(`Head Teacher ${headTeacherSignature.staff_name}`, 14, yPositionSignature - 10);
   
           // Add the label "Signature" next to the signature
           doc.setFontSize(12);
           doc.text('Signature:', 14, yPositionSignature);
   
           // Add the signature if successfully fetched
           if (signatureBase64) {
               const imageWidth = 20; // Set the small width for the signature
               const imageHeight = 10; // Set the small height for the signature        
               const signatureXPosition = 35; // Position the signature next to the "Signature" label on the left
   
               doc.addImage(signatureBase64, 'JPEG', signatureXPosition, yPositionSignature - 5, imageWidth, imageHeight);
           }
         
           // Save the PDF
           doc.save(`${student.first_name}_${student.last_name}_Report.pdf`);
         };
    
    
    const handleTermEditComments = () => {
        navigate(`/view-teacher-dashboard/edit-term-comments-report/${studentId}`, {
            state: {
                gradeId,
                studentId,
                student,
                grade,
                streamName,
                selectedTermId: selectedTerm,
                classTeachersComments: termComments.flatMap(comment => comment.class_teachers_comments),
                classTeachersRemarks: termComments.flatMap(remark => remark.general_remarks),
                studentAttendanceDays: termComments.flatMap(attendance=>attendance.attendance_days),
            }
        });

    };
    const handleYearEditComments = () => {
        navigate(`/view-teacher-dashboard/edit-summative-year-comments-report/${studentId}`, {
            state: {
                gradeId,
                studentId,
                student,
                grade,
                streamName,
                classTeachersComments: yearComments.flatMap(comment => comment.class_teachers_comments),
                classTeachersRemarks: yearComments.flatMap(remark => remark.general_remarks),
            }
        });

    };
    const fetchClassTeacherAssignments = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/get_class_teacher/${userId}`, {
                
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                // Convert assignments to a set for easy lookup
                const assignmentSet = new Set(data.map(assign => `${assign.grade_id}-${assign.stream_id}`));
                setAssignments(assignmentSet);
                // Check if the teacher is assigned to this specific grade and stream
                if (assignmentSet.has(`${gradeId}-${streamId}`)) {
                    setCanEdit(true);
                }
            } else {
                console.error('Failed to fetch class teacher details');
            }
        } catch (error) {
            console.error('Fetch Class Teacher Status Error:', error);
        }
    };

    return (
        <div className="report-container4">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
            </div>
            <CssBaseline />
      {/* Header */}
      <Box component="header" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {/* Display the school logo */}
        {schoolLogo && (
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={schoolLogo}
              alt={`${schoolName} Logo`}
              style={{
                width: '100px', // Set the desired width
                height: '100px', // Set the desired height
                objectFit: 'contain', // Ensure the image fits within the dimensions
                borderRadius: '50%', // Optional: Makes the logo circular
                border: '2px solid #ccc', // Optional: Adds a border
              }}
            />
          </Box>
        )}
        <Typography variant="h4" color="black">
  {schoolName ? `${schoolName}` : "School Name"}
</Typography>

        
      </Box>
            <h2>Summative Assessment for {student.first_name} {student.last_name}</h2>
            <div  className="student-info"  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100%", // Optional: ensures it takes up full height if needed
  }}>
                <p><strong>Admission Number:</strong> {student.admission_number}</p>
                <p><strong>Gender:</strong> {student.gender}</p>
                <p><strong>Stream:</strong> {streamName}</p>
                <p><strong>Grade:</strong> {grade}</p>
            </div>
    
            <div className="tabs-container">
            <div className="tabs">
                <button
                    className={`assign-button2 ${currentTab === 'term' ? 'active-tab' : ''}`}
                    onClick={() => setCurrentTab('term')}
                    style={{ backgroundColor: "#8ac429" }}
                >
                    View Summative Reports by Term
                </button>
                <button
                    className={`assign-button2 ${currentTab === 'year' ? 'active-tab' : ''}`}
                    onClick={() => setCurrentTab('year')}
                    style={{ backgroundColor: "#ffb134" }}
                >
                    View Summative Reports by Year
                </button>
            </div>
    
                <div className="tab-content">
                    {currentTab === 'term' ? (
                        <div className="term-report">
                            <h2>Summative Reports Per Term</h2>
                            <select value={selectedTerm} onChange={handleTermChange}>
                                <option value="">Select a Term</option>
                                {terms.map(term => (
                                    <option key={term.id} value={term.id}>
                                        {term.term_name}
                                    </option>
                                ))}
                            </select>
    
                            {Array.isArray(reportData) && reportData.length > 0 ? (
                                <>
                                    <button 
                                        onClick={handleDownloadReport} 
                                        style={{ marginTop: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                                    >
                                        <FaDownload style={{ marginRight: '5px' }} /> Download Report
                                    </button>
                                    <table className="report-table">
                                        <thead>
                                            <tr>
                                               <th>Term</th>          
                                                <th>Subject</th>
                                                <th>Exam 1 Marks</th>
                                                <th>Exam 2 Marks</th>
                                                <th>Exam 3 Marks</th>
                                                <th>Term Average Grade</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.map((report) => (
                                                <tr key={report.subject_id}>
                                                    <td>{selectedTermName}</td>
                                                    <td>{report.subject_name}</td>
                                                    <td>{report.term_exam1_marks}</td>
                                                    <td>{report.term_exam2_marks}</td>
                                                    <td>{report.term_exam3_marks}</td>
                                                    <td>{report.termly_average_grade}</td>
                                                    <td>
                                                        {editableSubjects.has(report.subject_id) && (
                                                            <button
                                                            style={{ marginTop: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                                                             onClick={() => handleEdit(report.subject_id, report)}>
                                                                <FaEdit /> Edit
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="teacher-comments">
                                    {canEdit && (
                                        <div className="edit-button-container">
                                            <button 
                                                onClick={handleTermEditComments} 
                                                className="edit-comments-button"
                                            >
                                                <FaEdit style={{ marginRight: '5px' }} /> Edit Comments and Attendance
                                            </button>
                                        </div>
                                    )}
                                    
                                    {termComments.length > 0 && (
                                    <div className="comments-section">
                                        <h3 style={{ textAlign: 'left' }}>Attendance Days</h3>
                                        <p>{termComments[0].attendance_days}</p> 
                                        <h3 style={{ textAlign: 'left' }}>Teacher's Comments</h3>
                                        <p>{termComments[0].class_teachers_comments || "No comments available"}</p> {/* Display the first teacher's comment */}
                                        <h3 style={{ textAlign: 'left' }}>General Remarks</h3>
                                        <p>{termComments[0].general_remarks || "No remarks available"}</p> {/* Display the first general remark */}
                                    </div>
                                   )}
                                </div>

                                </>
                                
                            ) : (
                                <p>No report data available for the selected Term.</p>
                            )}
                        </div>
                    ) : (
                        <div className="year-report">
                            <h2>Summative Reports Per Year</h2>
                            <select value={selectedYear} onChange={handleYearChange}>
                                <option value="">Select a Year</option>
                                {years.map(year => (
                                    <option key={year.id} value={year.id}>
                                        {year.year_name}
                                    </option>
                                ))}
                            </select>
    
                            {Array.isArray(reportData) && reportData.length > 0 ? (
                                <>
                                    <button 
                                        onClick={handleDownloadReport} 
                                        style={{ marginTop: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                                    >
                                        <FaDownload style={{ marginRight: '5px' }} /> Download Report
                                    </button>
                                    <table className="report-table">
                                        <thead>
                                            <tr>
                                                <th>Subject</th>
                                                <th>Term 1 Average</th>
                                                <th>Term 2 Average</th>
                                                <th>Term 3 Average</th>
                                                <th>Yearly Average Grade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.map((report) => (
                                                <tr key={report.id}>
                                                    <td>{report.subject_name}</td>
                                                    <td>{report.annual_exam1_marks}</td>
                                                    <td>{report.annual_exam2_marks}</td>
                                                    <td>{report.annual_exam3_marks}</td>
                                                    <td>{report.annual_average_grade}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="teacher-comments">
                                        {canEdit && (
                                            <div className="edit-button-container">
                                            <button 
                                                onClick={handleYearEditComments} 
                                                style={{ marginBottom: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                                            >
                                                <FaEdit style={{ marginRight: '5px' }} /> Edit  Comments
                                            </button>
                                            </div>
                                        )}

                            {yearComments.length > 0 && (
                            <div className="comments-section">
                                <h3 style={{ textAlign: 'left' }}>Teacher's Comments</h3>
                                <p>{yearComments[0].class_teachers_comments || "No comments available"}</p> {/* Display the first teacher's comment */}
                                <h3 style={{ textAlign: 'left' }}>General Remarks</h3>
                                <p>{yearComments[0].general_remarks || "No remarks available"}</p> {/* Display the first general remark */}
                            </div>
                        )}
                                    </div>
                                </>
                            ) : (
                                <p>No report data available for the selected year.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );}
    
export default EditSummativeAssessments;
    