import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Paper, Typography } from '@mui/material';
import { retrieve } from "./Encryption";
import { useOutletContext, useNavigate } from 'react-router-dom'; // Import necessary hooks

const ParentViewReports = () => {
  const [parent, setParent] = useState(null);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Access schoolId from Outlet context
  const { schoolId, parentId, schoolName, schoolLogo } = useOutletContext();

  useEffect(() => {
    const fetchParentDetails = async () => {
      try {
        const response = await fetch(`https://cbc-orfl.onrender.com/parents/${parentId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${retrieve().access_token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setParent(data); // Set the full parent object
          setStudents(data.students || []); // Extract students array
        } else {
          const errorData = await response.json();
          setError(errorData.error || 'Failed to fetch parent details');
        }
      } catch (err) {
        setError('An error occurred while fetching parent details.');
        console.error('Fetch Parent Details Error:', err);
      }
    };

    fetchParentDetails();
  }, [parentId]);

  const handleNavigation = (assessmentType, studentId, gradeId, streamId) => {
    navigate(`/view-parent-dashboard/${assessmentType}/${studentId}/${gradeId}/${streamId}`, {
      state: {
        gradeId: gradeId,
        streamId: streamId,
        students
      }
    });
  };
  
  
  

  const renderStudentDetails = (student) => (
    <Box key={student.id} sx={{ marginBottom: 4 }}>
      {/* Student Details */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="h6">
          {student.first_name} {student.last_name}
        </Typography>
        <Typography>
          <strong>Admission Number:</strong> {student.admission_number}
        </Typography>
        <Typography>
          <strong>Grade:</strong> {student.grade || 'N/A'}
        </Typography>
        <Typography>
          <strong>Stream:</strong> {student.stream_name || 'N/A'}
        </Typography>
        
        {/* Buttons for each student */}
        <Box 
          sx={{ 
            display: 'flex', 
            gap: '40px', 
            justifyContent: 'center', 
            marginTop: "20px" 
          }}
        >
          <button 
            className="assign-button2" 
            style={{ backgroundColor: "#8ac429", fontFamily: "cursive", fontSize: "17px" }} 
            onClick={() => handleNavigation('parent-view-formative-assessments', student.id, student.grade_id, student.stream_id)}
          >
            View Formative Assessments   
          </button>
          <button 
            className="assign-button2" 
            style={{ backgroundColor: "#ffb134", fontFamily: "cursive", fontSize: "17px" }} 
            onClick={() => handleNavigation('parent-view-summative-assessments', student.id, student.grade_id, student.stream_id)}
          >
            View Summative Assessments
          </button>
          <button 
            className="assign-button2" 
            style={{ backgroundColor: "#ff615d", fontFamily: "cursive", fontSize: "17px" }} 
            onClick={() => handleNavigation('parent-view-behaviour-goals-assessments', student.id, student.grade_id, student.stream_id)}
          >
            View Behaviour Goals Assessments
          </button>
        </Box>
      </Paper>
    </Box>
  );
  

  return (
    <Box
      className="dashboard-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        position: 'relative',
        zIndex: 0,
      }}
    >
      <CssBaseline />

      {/* Header */}
      <Box component="header" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {schoolLogo && (
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={schoolLogo}
              alt={`${schoolName} Logo`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'contain',
                borderRadius: '50%',
                border: '2px solid #ccc',
              }}
            />
          </Box>
        )}
        <Typography variant="h4" color="black">
          {schoolName ? `${schoolName} Dashboard` : 'School Dashboard'}
        </Typography>
      </Box>

      {/* Students and Charts */}
      {students.length > 0 ? (
        students.map(renderStudentDetails)
      ) : (
        <Typography>No students linked to this parent.</Typography>
      )}
    </Box>
  );
};

export default ParentViewReports;
