import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { retrieve } from "./Encryption";
import { FaArrowLeft } from 'react-icons/fa';
import "./form.css";

const UploadSignature = () => {
    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState('');
    const [signature, setSignature] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const MAX_FILE_SIZE = 10000000; // 10MB
    const validFileExtensions = ["jpg", "png", "jpeg", "webp"];

    useEffect(() => {
        const fetchStaff = async () => {
            try {
                const response = await fetch('https://cbc-orfl.onrender.com/head-teachers', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${retrieve().access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setStaffList(data);
                } else {
                    const errorData = await response.json();
                    setError(errorData.error || 'Failed to fetch staff list.');
                }
            } catch (error) {
                console.error('Error fetching staff:', error);
                setError('Something went wrong. Please try again later.');
            }
        };

        fetchStaff();
    }, []);

    const getExtension = (fileName) => {
        const parts = fileName.split(".");
        return parts[parts.length - 1].toLowerCase();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const size = file.size;
            const extension = getExtension(file.name);
            const isValid = validFileExtensions.includes(extension);
            if (size > MAX_FILE_SIZE) {
                setError("The file is too large. Maximum size is 10MB.");
                setSignature(null);
            } else if (!isValid) {
                setError("The file type is not supported. Allowed types: jpg, jpeg, png, webp.");
                setSignature(null);
            } else {
                setError('');
                setSignature(file);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!signature) {
            setError("Please select a signature file to upload.");
            return;
        }

        if (!selectedStaff) {
            setError("Please select a staff member.");
            return;
        }

        const form = new FormData();
        form.append("signature", signature);

        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/signature_upload/${selectedStaff}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${retrieve().access_token}`,
                },
                body: form,
            });

            if (response.ok) {
                const data = await response.json();
                setSuccess("Signature uploaded successfully!");
                setError('');
              
                setTimeout(() => {
                    navigate(-1);
                }, 10000);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to upload signature.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error uploading signature:', error);
            setError('Something went wrong. Please try again.');
            setSuccess('');
        }
    };

    return (
        <div className='content-wrapper'>
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
                <button className="back-button" onClick={() => navigate(-1)}>
                    <FaArrowLeft /> Back
                </button>
            </div>

            <h2 className='header'>Upload Staff Signature</h2>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    {error && <div className="ui negative message">{error}</div>}
                    {success && <div className="ui positive message">{success}</div>}

                    <div className="field">
                        <label>Select Staff:</label>
                        <select
                            value={selectedStaff}
                            onChange={(e) => setSelectedStaff(e.target.value)}
                        >
                            <option value="">-- Select Staff --</option>
                            {staffList.map((staff) => (
                                <option key={staff.id} value={staff.id}>
                                    {staff.first_name} {staff.last_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="field">
                        <label>Upload Signature:</label>
                        <input
                            type="file"
                            name="signature"
                            accept=".jpg,.jpeg,.png,.webp"
                            onChange={handleFileChange}
                        />
                        {error && error.toLowerCase().includes("file") && (
                            <div className="error-message">{error}</div>
                        )}
                    </div>

                    <div>
                        <button type="submit" className='submit'>
                            Upload Signature
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UploadSignature;
