import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { retrieve } from './Encryption';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './AdminViewStudentBehaviourReport.css';
import { FaDownload, FaEdit,FaArrowLeft } from 'react-icons/fa';
import { useOutletContext } from 'react-router-dom'; // Import the hook to access context
import { Box, CssBaseline,Typography } from '@mui/material';
const EditStudentBehaviourGoalsReport = () => {
    const location = useLocation();
    const { studentId } = useParams();
    const { gradeId, streamId, student, grade,streamName } = location.state;
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
  
  
    const [canEdit, setCanEdit] = useState(false);
    const [assignments, setAssignments] = useState(new Set());

    const { schoolId, userId, schoolName, schoolLogo, schoolContact,schoolAddress,schoolEmail  } = useOutletContext();
    const [headTeacherSignature, setHeadTeacherSignature]=useState("")
  
    const navigate = useNavigate();

      const fetchHeadTeacherSignature = async () => {
                try {
                  const response = await fetch("https://cbc-orfl.onrender.com/get_signature", {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                  });
              
                  if (response.ok) {
                    const data = await response.json();
                    setHeadTeacherSignature(data); 
                  } else {
                    console.error("Failed to fetch headteacher's signature");
                  }
                } catch (error) {
                  console.error("Error:", error);
                }
              };

    const fetchClassTeacherAssignments = async () => {
        try {
            const response = await fetch(`https://cbc-orfl.onrender.com/get_class_teacher/${userId}`, {
                
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrieve().access_token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                // Convert assignments to a set for easy lookup
                const assignmentSet = new Set(data.map(assign => `${assign.grade_id}-${assign.stream_id}`));
                setAssignments(assignmentSet);
                // Check if the teacher is assigned to this specific grade and stream
                if (assignmentSet.has(`${gradeId}-${streamId}`)) {
                    setCanEdit(true);
                }
            } else {
                console.error('Failed to fetch class teacher details');
            }
        } catch (error) {
            console.error('Fetch Class Teacher Status Error:', error);
        }
    };

    useEffect(() => {
        const fetchBehaviourReport = async () => {
            try {
                const response = await fetch(`https://cbc-orfl.onrender.com/behaviour_reports/${studentId}/${gradeId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${retrieve().access_token}`,
                    },
                });
                const data = await response.json();
                if (data.behaviour_reports) {
                    setReportData(Array.isArray(data.behaviour_reports) ? data.behaviour_reports : [data.behaviour_reports]);
                } else {
                    console.error('Error fetching behaviour report:', data.error);
                }
            } catch (error) {
                console.error('Error fetching behaviour report:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBehaviourReport();
        fetchClassTeacherAssignments();
        fetchHeadTeacherSignature()
    }, [studentId, gradeId, streamId, userId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!reportData.length) {
        return <p>No behavior report found for this student and grade combination.</p>;
    }

    
        const handleDownloadPDF = async () => {
            const doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.getWidth(); // Get the document width
            const pageHeight = doc.internal.pageSize.getHeight(); // Get the document height
            const fontSizeHeader = 10; // Font size for headers
            const fontSizeBody = 8; // Font size for body text
            const fontSizeSmall = 6; // Font size for smaller text like address and contact
        
            const fetchLogo = async () => {
                try {
                    let logoUrl = schoolLogo;
          
                    // Replace 'http' with 'https' if necessary
                    if (logoUrl.startsWith('http://')) {
                        logoUrl = logoUrl.replace('http://', 'https://');
                    }
          
                    const response = await fetch(logoUrl);
                    if (!response.ok) throw new Error("Failed to fetch logo");
          
                    const blob = await response.blob();
                    const reader = new FileReader();
          
                    return new Promise((resolve, reject) => {
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    });
                } catch (error) {
                    console.error("Failed to load logo:", error);
                    return null; // Provide a fallback or skip adding the logo
                }
            };
          
            const logoBase64 = await fetchLogo();
          
            // Add the logo if successfully fetched
            if (logoBase64) {
                const imageWidth = 10; // Set the small width for the logo
                const imageHeight = 10; // Set the small height for the logo
                const logoXPosition = (pageWidth - imageWidth) / 2; // Center the logo horizontally
                const yPositionLogo = 10; // Top margin for the logo
          
                // Draw circular border around the logo
                const centerX = logoXPosition + imageWidth / 2;
                const centerY = yPositionLogo + imageHeight / 2;
                const radius = imageWidth / 2;
          
                doc.setLineWidth(0.5);
                doc.addImage(logoBase64, 'JPEG', logoXPosition, yPositionLogo, imageWidth, imageHeight);
            }
          
            const fetchSignature = async () => {
                try {
                    let signatureUrl = headTeacherSignature.signature_url;
          
                    // Replace 'http' with 'https' if necessary
                    if (signatureUrl.startsWith('http://')) {
                        signatureUrl = signatureUrl.replace('http://', 'https://');
                    }
          
                    const response = await fetch(signatureUrl);
                    if (!response.ok) throw new Error("Failed to fetch signature");
          
                    const blob = await response.blob();
                    const reader = new FileReader();
          
                    return new Promise((resolve, reject) => {
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    });
                } catch (error) {
                    console.error("Failed to load signature:", error);
                    return null; // Provide a fallback or skip adding the signature
                }
            };
          
            const signatureBase64 = await fetchSignature();
    
            doc.setFontSize(16);
           // Add report type below the school details
           doc.setFontSize(fontSizeHeader);
           const title = "  Behaviour Assessment Report" ;
         const titleWidth = doc.getTextWidth(title); // Get the width of the text
            // For the title
            const titleXPosition = (pageWidth - titleWidth) / 2; // Center the title text
            doc.text(title, titleXPosition, 40); // Add the text at the calculated position
          
    
          
    
           // Add title and school details
           doc.setFontSize(fontSizeHeader);
       
          
           doc.text(schoolName, pageWidth / 2, 25, { align: 'center' });
    
           doc.setFontSize(fontSizeSmall);
           doc.text(`Address: ${schoolAddress}`, pageWidth / 2, 28, { align: 'center' });
           doc.text(`Contact: ${schoolContact}`, pageWidth / 2, 31, { align: 'center' });
           doc.text(`Email: ${schoolEmail}`, pageWidth / 2, 34, { align: 'center' });
       
           
           
           // Draw a bordered and shaded box for student information
           const studentInfoX = 34; // X position for the box
           const studentInfoY = 48; // Y position for the box
           const studentInfoWidth = pageWidth - 65; // Box width (centered with 20px padding on each side)
           const studentInfoHeight = 25; // Increased box height to accommodate term name
       
           // Set border and fill colors
           doc.setLineWidth(0.1); // Reduced border weight for a thinner line      
           doc.setFillColor(243, 243, 244); // Light gray fill (RGB)
       
           // Draw the rectangle
           doc.rect(studentInfoX, studentInfoY, studentInfoWidth, studentInfoHeight, 'FD'); // 'FD' = Fill and Draw
       
           doc.setFontSize(fontSizeBody);
           doc.text(`Name: ${student.first_name} ${student.last_name}`, pageWidth / 2, studentInfoY + 5, { align: 'center' });
           doc.text(`Admission Number: ${student.admission_number}`, pageWidth / 2, studentInfoY + 10, { align: 'center' });
           doc.text(`Grade: ${grade} | Stream: ${streamName}`, pageWidth / 2, studentInfoY + 15, { align: 'center' });
       
    
            const columns = ["Behaviour Goal", "Assessment"];
            const rows = reportData.flatMap(report => report.behaviour_goals_reports.map(goal => [
                goal.behaviour_goal,
                goal.behaviour_goal_assessment,
            ]));
    
            doc.autoTable(columns, rows, {
                startY: 85,
                styles: { fontSize: 10, cellPadding: 2 },
                headStyles: { halign: 'center' },
                columnStyles: { 0: { cellWidth: 100 }, 1: { cellWidth: 90 } },
            });
    
            doc.text("Class Teacher's Comments:", 14, doc.autoTable.previous.finalY + 10);
            doc.text(reportData[0].class_teachers_comments || "No comments available", 14, doc.autoTable.previous.finalY + 20);
          
            const yPositionSignature = pageHeight - 40; // Place signature near the bottom of the page
            // Add head teacher's name
            doc.setFontSize(12);
            doc.text(`Head Teacher ${headTeacherSignature.staff_name}`, 14, yPositionSignature - 10);
    
            // Add the label "Signature" next to the signature
            doc.setFontSize(12);
            doc.text('Signature:', 14, yPositionSignature);
    
            // Add the signature if successfully fetched
            if (signatureBase64) {
                const imageWidth = 20; // Set the small width for the signature
                const imageHeight = 10; // Set the small height for the signature        
                const signatureXPosition = 35; // Position the signature next to the "Signature" label on the left
    
                doc.addImage(signatureBase64, 'JPEG', signatureXPosition, yPositionSignature - 5, imageWidth, imageHeight);
            }
    
            doc.save(`Behaviour_Report_${student.first_name}_${student.last_name}.pdf`);
        };


    const handleEditAssessments = () => {
        navigate(`/view-teacher-dashboard/edit-behaviour-goals-report/${studentId}`, {
            state: {
                gradeId,
                studentId,
                student,
                grade,
                streamName,
                behaviourGoalsReports: reportData.flatMap(report => report.behaviour_goals_reports),
            }
        });
    };
    const handleEditComments = () => {
        navigate(`/view-teacher-dashboard/edit-behaviour-comments-report/${studentId}`, {
            state: {
                gradeId,
                studentId,
                student,
                grade,
                streamName,
                classTeachersComments: reportData.flatMap(report => report.class_teachers_comments),
            }
        });
    };

    return (
        <div className="behaviour-report">
            <div className="top-navigation" style={{ display: 'flex', alignItems: 'center' }}>
            <button className="back-button" onClick={() => navigate(-1)}>
                <FaArrowLeft /> Back
            </button>
            </div>
            <CssBaseline />
      {/* Header */}
      <Box component="header" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {/* Display the school logo */}
        {schoolLogo && (
          <Box sx={{ marginBottom: 2 }}>
            <img
              src={schoolLogo}
              alt={`${schoolName} Logo`}
              style={{
                width: '100px', // Set the desired width
                height: '100px', // Set the desired height
                objectFit: 'contain', // Ensure the image fits within the dimensions
                borderRadius: '50%', // Optional: Makes the logo circular
                border: '2px solid #ccc', // Optional: Adds a border
              }}
            />
          </Box>
        )}
        <Typography variant="h4" color="black">
  {schoolName ? `${schoolName}` : "School Name"}
</Typography>

        
      </Box>
            <h2>Behaviour Assessment for {student.first_name} {student.last_name}</h2>
            <div  className="student-info"  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100%", // Optional: ensures it takes up full height if needed
  }}>
                <p><strong>Admission Number:</strong> {student.admission_number}</p>
                <p><strong>Gender:</strong> {student.gender}</p>
                <p><strong>Stream:</strong> {streamName}</p>
                <p><strong>Grade:</strong> {grade}</p>
            </div>
                   <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                 <button
                   onClick={handleDownloadPDF}
                   style={{
                     backgroundColor: "#4CAF50",
                     color: "white",
                     border: "none",
                     padding: "10px 15px",
                     cursor: "pointer",
                   }}
                 >
                   <FaDownload style={{ marginRight: "5px" }} /> Download Report
                 </button>
               </div>
            
            {canEdit && (
                <button onClick={handleEditAssessments} 
                    style={{ marginBottom: '10px', marginLeft: "1000px", backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                >
                    <FaEdit style={{ marginRight: '5px' }} /> Edit Report
                </button>
            )}
            
        

            <table className="behavior-table">
                <thead>
                    <tr>
                        <th className="goal-column">Behaviour Goal</th>
                        <th className="assessment-column">Assessment</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.map(report =>
                        report.behaviour_goals_reports.map((goal) => (
                            <tr key={goal.behaviour_goal_id}>
                                <td className="goal-column">{goal.behaviour_goal}</td>
                                <td className="assessment-column">{goal.behaviour_goal_assessment}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            <div className="teacher-comments">
            <h3>Class Teacher's Comments:</h3>
                {canEdit && (
                    <button 
                        onClick={handleEditComments} 
                        style={{ marginBottom: '10px',  marginLeft:"950px", backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 15px', cursor: 'pointer' }}
                    >
                        <FaEdit style={{ marginRight: '5px' }} /> Edit Comments
                    </button>
                )}

              
                <p >{reportData[0].class_teachers_comments || "No comments available"}</p>
            </div>
        </div>
    );
};

export default EditStudentBehaviourGoalsReport;