import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom"; // Import Outlet from react-router-dom
import { retrieve } from "./Encryption";
import { jwtDecode } from "jwt-decode";
import SidebarAdmin from "./SidebarAdmin";
import SidebarTeacher from "./SidebarTeacher";
import SidebarSuperAdmin from "./SidebarSuperAdmin"; // Import Sidebar for Super Admin
import SidebarParent from "./SidebarParent";
import Header from "./Header";
import "./DashboardLayout.css";

const DashboardLayout = () => {
  const decryptedToken = retrieve();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const [isPasswordChangeRequired, setIsPasswordChangeRequired] = useState(false);
  const [designationCode, setDesignationCode] = useState(null); // Use state to manage designationCode
  const [schoolId, setSchoolId]=useState(null)
  const [schoolName, setSchoolName] = useState('');
  const [schoolLogo, setSchoolLogo] = useState('');
  const [schoolContact,setSchoolContact]=useState('');
  const [schoolEmail, setSchoolEmail]=useState('');
  const [schoolAddress, setSchoolAddress]=useState('');
  const [userId, setUserId] = useState("");
  const [parentId, setParentId] = useState("");




  useEffect(() => {
    if (decryptedToken && decryptedToken.access_token) {
      try {
        const decodedToken = jwtDecode(decryptedToken.access_token);
        setDesignationCode(decodedToken.designation); // Set the designation using state
        setSchoolId(decodedToken.school_id)
        setUserId(decodedToken.staff_id);
        setParentId(decodedToken.parent_id);

        // Check if password change is required
        setIsPasswordChangeRequired(decryptedToken.change_password_required);
      } catch (error) {
        console.error("Error decoding the token:", error);
      }
    }
  }, [decryptedToken]); // Only run on mount when decryptedToken changes

  useEffect(() => {
    // If password change is not required, show the sidebar
    if (!isPasswordChangeRequired) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [isPasswordChangeRequired]); // Only run when isPasswordChangeRequired changes
 
  const fetchSchoolDetails = async () => {
    if (!schoolId) return; // Ensure schoolId is available
    try {
      const response = await fetch(`https://cbc-orfl.onrender.com/schools/${schoolId}`);
      if (response.ok) {
        const data = await response.json();
        setSchoolName(data.name); // Set the school name
        setSchoolLogo(data.logo_url); // Set the school logo URL
        setSchoolContact(data.contact);
        setSchoolAddress(data.address);
        setSchoolEmail(data.email);
      } else {
        console.error("Failed to fetch school details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  useEffect(() => {
    fetchSchoolDetails();
  }, [schoolId]); // Re-fetch if schoolId change
  





  return (
    <div className="dashboard-layout">
      <Header />
      <div className="dashboard-body">
        {/* Display the password change prompt if required */}
        {isPasswordChangeRequired ? (
          <div className="password-change-required">
            <h2>Password Change Required</h2>
            <p>Please change your password before accessing the dashboard.</p>
          </div>
        ) : (
          // Show the sidebar if no password change is required
          showSidebar && (
            <div className="sidebar">
              {designationCode === 100 && <SidebarSuperAdmin />}
              {designationCode === 101 && <SidebarAdmin />}
              {designationCode === 102 && <SidebarTeacher />}
              {designationCode === 103 && <SidebarParent />}
            </div>
          )
        )}
        <div className="dashboard-content">
          {/* Render nested routes using the Outlet component */}
          <Outlet context={{ schoolId,userId,parentId,schoolName,schoolLogo,schoolContact,schoolAddress,schoolEmail }} />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
