import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

const AdminTasks = () => {
    const navigate = useNavigate();
    return (
        <div 
        className="view-teachers-container" 
        
      >
      
            <Header as="h2" textAlign="center" className='header'>
                Admin Tasks
            </Header>

            <p style={{ textAlign: "center", marginBottom: "20px" }}>
                Select a task to perform:
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <button 
                    className="assign-button2" 
                    style={{ 
                        backgroundColor: "#8ac429", 
                        fontFamily: "cursive", 
                        fontSize: "17px", 
                        padding: "10px 20px", 
                        borderRadius: "5px", 
                        cursor: "pointer" 
                    }} 
                    onClick={() => navigate('/view-admin-dashboard/upload-signature')}
                >
                    Upload E-Signature
                </button>
            </div>
        </div>
    );
};

export default AdminTasks;
