import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaRegClipboard,
  FaChartPie,
  FaShieldAlt,
  FaUserTie,
  FaMobileAlt,
  FaClock,
  FaChartBar,
  FaCogs,
  FaFileAlt,
} from "react-icons/fa";
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      {/* Navigation Bar */}
      <nav className="navbar">
        <img src="/logonew1.png" alt="CBC App Hero" />
        <button className="login-button3" onClick={() => navigate("/login")}>
          Login
        </button>
      </nav>

      {/* Hero Section */}
      <header className="hero">
        <img src="/hero-image.jpg" alt="CBC App Hero" className="hero-image" />
        <div className="hero-content">
          <h1>The CBC App: Revolutionizing Education Assessment in Kenya</h1>
          <p>
            Streamline assessments, simplify record-keeping, and enhance report
            preparation with our innovative CBC App.
          </p>
          <button className="cta-button" onClick={() => navigate("/request-info")}>
            Request a Demo
          </button>
        </div>
      </header>

      {/* Benefits Section */}
      <section className="benefits" id="benefits">
        <h2>Solving Key Problems</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <FaRegClipboard className="icon" />
            <h3>Efficiency in Record-Keeping</h3>
            <p>
              Reduce the time required for recording assessments from 1 hour to
              just 10 minutes.
            </p>
          </div>
          <div className="benefit">
            <FaChartPie className="icon" />
            <h3>Enhanced Report Preparation</h3>
            <p>
              Complete end-of-term reports in just one hour instead of an entire
              day.
            </p>
          </div>
          <div className="benefit">
            <FaShieldAlt className="icon" />
            <h3>Reliability</h3>
            <p>
              Secure and easily accessible digital records eliminate the risk of
              losing important learner information.
            </p>
          </div>
          <div className="benefit">
            <FaUserTie className="icon" />
            <h3>Improved Teacher Efficiency</h3>
            <p>
              Manage assessments and reports directly from your computer.
            </p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features" id="features">
        <h2>Key Features</h2>
        <div className="features-grid">
          <div className="feature">
            <FaMobileAlt className="icon" />
            <h3>Mobile-Friendly</h3>
            <p>Keep all records on your phone and eliminate physical books.</p>
          </div>
          <div className="feature">
            <FaClock className="icon" />
            <h3>Time-Saving</h3>
            <p>
              Reduce the time spent on assessment recording and report
              preparation.
            </p>
          </div>
          <div className="feature">
            <FaChartBar className="icon" />
            <h3>Comprehensive Analytics</h3>
            <p>
              Access detailed records for every learner in every class.
            </p>
          </div>
          <div className="feature">
            <FaCogs className="icon" />
            <h3>Effortless Management</h3>
            <p>
              Real-time analytics make class and teacher management seamless.
            </p>
          </div>
          <div className="feature">
            <FaFileAlt className="icon" />
            <h3>Professional Reporting</h3>
            <p>Create and share reports directly from mobile devices.</p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; 2025 CBC App. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
